import styled from "@emotion/styled";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Calculator from "./components/Calculator";
import BusinessCalculatorDescribed from "./components/BusinessCalculatorDescribed";
import UserAgreement from "./components/UserAgreement";

const Main = styled.main`
  max-width: 940px;
  margin: auto;
  height: 100%;
`;

/*
Calculator (Calculator.js) contains all of the main code for the calculator UI and logic
*/
function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Main className="App">
                <Navbar />
                <BusinessCalculatorDescribed />
                <Calculator />
              </Main>
              <Footer />
            </>
          }
        />
        <Route
          path="/useragreement"
          element={
            <>
              <Main className="App">
                <Navbar />
                <UserAgreement />
              </Main>
              <Footer />
            </>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
