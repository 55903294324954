// pulled in and converted from Corey's mockup:
// https://givenapp.webflow.io/nonprofit-review-mock
import {
  GRATEFUL_APP_URL,
  GRATEFUL_BUSINESS_ABOUT_URL,
  GRATEFUL_EMAIL,
} from "../constants";

export default function Navbar({ setModalStates }) {
  return (
    <header
      data-collapse="medium"
      data-animation="over-right"
      data-duration="500"
      data-doc-height="1"
      data-easing="ease-out-quart"
      data-easing2="ease-in"
      role="banner"
      className="nav w-nav"
    >
      <div className="container nav-container">
        <a
          href={GRATEFUL_APP_URL}
          className="nav-logo_link w-nav-brand"
          aria-label="home"
        >
          <img
            src="https://assets.website-files.com/5f39f33729d6e31e12d6a9c7/5fea9a55fdc95833e3aafee3_main-logo.svg"
            alt=""
            className="nav-logo grateful"
          />
        </a>
        <nav role="navigation" className="nav-menu w-nav-menu">
          <a href={GRATEFUL_BUSINESS_ABOUT_URL} className="nav-link w-nav-link">
            About Grateful
          </a>
          <a href={`mailto:${GRATEFUL_EMAIL}`} className="nav-link w-nav-link">
            Contact Us
          </a>
        </nav>
      </div>
      <div className="nav-overlay" style={{ opacity: 0 }}></div>
      <div
        className="w-nav-overlay"
        data-wf-ignore=""
        id="w-nav-overlay-0"
      ></div>
    </header>
  );
}
