import TextField from "@mui/material/TextField";

export default function TextInput({ name, value, handleChange, ...props }) {
  return (
    <TextField
      id={name}
      name={name}
      label=""
      variant="outlined"
      inputProps={{ style: { padding: 10, textAlign: 'center' } }}
      sx={{
        "width": 150,
        "& .MuiInputBase-root.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.6)", // (default alpha is 0.38)
        },
      }}
      value={value || ''}
      onChange={handleChange}
      {...props}
    />
  );
}
