// TODO: Change this to change all the instances of this URL in the app
export const GRATEFUL_APP_URL = "https://app.begrateful.org";
export const GRATEFUL_FAQ_URL = "https://www.begrateful.org/nonprofits#faq";
export const GRATEFUL_TWITTER_URL = "https://twitter.com/gratefulapp";
export const GRATEFUL_LINKEDIN_URL =
  "https://www.linkedin.com/company/gratefulapp/";
export const GRATEFUL_APPLE_APP_URL =
  "https://apps.apple.com/us/app/given/id1454844119";
export const GRATEFUL_GOOGLE_APP_URL =
  "https://play.google.com/store/apps/details?id=app.given.Given";
export const GRATEFUL_BUSINESS_ABOUT_URL =
  "https://www.begrateful.org/businesses";

export const GRATEFUL_EMAIL = "sales@begrateful.org";

// TODO: To load prod config in prod, swap out this string with Top Level Domain of PROD_URL
// Note: do not include https:// in PROD_URL because window.location.host does not include it
// This is used in `config.js`
export const PROD_URL = "calculator.begrateful.org";
