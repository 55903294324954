import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import InputField from "./InputField";
import BoldInputField from "./BoldInputField";
import MuiButton from "./MuiButton";

import { useLocation } from "react-router-dom";
import { doc, getDoc, updateDoc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

import axios from "axios";

/*

This file contains all of the main code the calculator UI and logic.
The only custom items (not a standard MUI UI element) that are separate are InputField (the input fields with custom styling) and BoldInputField (same but with bold text).

It could be better to break out this code until multiple subcomponents in the future, but for now having it in one place hopefully makes it easier to tweak as our sales team tests it with prospects.

State is managed using the state variable below and useReducer (dispatch/reduce). 
https://reactjs.org/docs/hooks-reference.html#usereducer

The calculator does NOT use Formik for form state management (unlike the business signup form and the nonprofit update profile form).

Basic structure:
- Calculator function at the bottom of this file renders the UI.
- On initial load, Calculator calls checkforSavedValues.
- checkForSavedValues checks if an ID was passed in a URL parameter (optional), if yes then it checks Firestore for saved values.
- When the user updates data in a field, the field name and value is passed to dispatch/reducer as a string.
- The field value is converted to a number so we can perform math with it (convertToNumber function below).
- The number is then formatted as appropriate using the following functions below: formatNumber, formatDollars, formatPercent, removeTrailingZeros
- The formatted value is added to the state variable, and the screen re-renders with the new value(s)
- The calculate function is also called to calculate fields like total donation amount, subscription fee and ROI if all of the required fields are populated
- saveAndReturn also saves the state values to Firestore every time the user changes something, if we have a company ID

Currently the calculator values are saved in the 'calculator' map on the companies document in Firestore.

*/


/*
Initially set all values to empty, this will intentionally show all of the form fields as blank/empty while it is loading
*/
var initialState = { 
  initialLoad: true, // used to check if this is the initial load of the page
  loading: false, // triggers the backdrop loading overlap
  showOptionalDetails: false, // show or hide optional details section at bottom
  companyId: null,
  companyName: 'Loading...', // shows at top of screen
  businessSizeType: 'customers', // can be either 'customers' or 'sales' (start with customer number or start with total sales number)
  customers: 0,
  average: 0,
  averageAuto: 0, // average is used when inputting manually, averageAuto is used when average is auto calculated
  totalSales: 0,
  totalSalesAuto: 0, // totalSales is used when inputting manually, totalSalesAuto is used when totalSales is auto calculated
  givingType: 'percent', // can be either 'percent' or 'fixed' (donate a percent of each purchase or donate a fixed amount per purchase/customer)
  givingPercent: 0,
  givingAmount: 0,
  participationPercent: 0,
  donation: 0,
  subscriptionFee: 0,
  customerIncreasePercent: 0,
  marketingIncreasePercent: 0,
  grossROI: 0,
  profitPercent: 0, // optional
  netROI: 0, // optional
};

/*
After loading, use these defaults if saved values not found for any of the following fields
*/
var defaultValues = { 
  companyName: 'Calculate your business ROI from joining Grateful Giving', // default header message if we don't know the business name
  businessSizeType: 'customers',
  givingType: 'percent',
  givingPercent: '1%',
  participationPercent: '40%',
  customerIncreasePercent: '15%',
  marketingIncreasePercent: '5%',
};


/*
On inital load, check if a company ID was passed and if it was, check Firestore for any saved values to populate in the calculator
*/
async function checkForSavedValues(search, dispatch) {

  // companyId can be passed in a URL parameter as id= or company= (in that priority order)
  var companyId = new URLSearchParams(search).get("id");

  if (companyId == null) {
    companyId = new URLSearchParams(search).get("company");
  }

  // If a company ID was found
  if (companyId != null) {

    dispatch({ name: 'loading', value: true }); // Show the loading backdrop
    var savedData = null;

    // Check Firestore
    const getFirestoreData = async () => {
      const docSnapshot = await getDoc(
        doc(db, "companies", companyId)
      );
      var data = docSnapshot?.data();
      savedData = data.calculator;

      // If savedData is null but the company doc has a company name
      if ((savedData == null) && (data.name != null)) {
        savedData = { companyName: data.name };

      // Else if savedData is not null but is missing the company name and the company doc has a company name
      } else if ((savedData != null) && (savedData.companyName == null) && (data.name != null)) {
        savedData.companyName = data.name;
      }

      if (savedData != null) {
        dispatch({ name: 'onInitialLoad', value: { ...defaultValues, ...savedData, companyId: companyId } });
        dispatch({ name: 'loading', value: false }); // Turn off the loading backdrop

      // If no saved data found, instead use default values
      } else {
        dispatch({ name: 'onInitialLoad', value: defaultValues });    
        dispatch({ name: 'loading', value: false }); // Turn off the loading backdrop
      }

      return data.name;

    };

    var companyName = await getFirestoreData(companyId);

    const saveView = async () => {

      // check the viewer's IP address to exclude members of the Grateful team
      try {
        const getIp = await axios.get('https://us-central1-given-e6ba8.cloudfunctions.net/getIpAddress');
        // var excluded = ['173.17.226.160', '173.20.162.229', '98.127.128.34'];
        var excluded = [];

        if (!excluded.includes(getIp.data)) {
          setDoc(doc(db, "company-signup-views", companyId), {
            datetime: new Date(),
            contact: '',
            company: companyName,
            ip: getIp.data,
            action: 'ROI calculator viewed'
          }, { merge: true });
        }

      // if there are any issues, default to saving the view event
      } catch (error) {
        setDoc(doc(db, "company-signup-views", companyId), {
          datetime: new Date(),
          contact: '',
          company: companyName,
          ip: null,
          action: 'ROI calculator viewed'
        }, { merge: true });
      }
    }
    saveView(companyName);

    return savedData;

  // If no company ID, instead use default values
  } else {
    dispatch({ name: 'onInitialLoad', value: defaultValues });    
  }
}


// Remove trailing zeros that were added by number conversion
const removeTrailingZeros = (string) => {
  if (string.slice(-3) == '.00') {
    return string.slice(0,string.length-3);
  } else if (string.slice(-2) == '.0') {
    return string.slice(0,string.length-2);
  } else {
    return string;
  }  
};

/*
Format a number with commas, "trailing" is used to include any decimals manually typed in by the user
*/
const formatNumber = (amount, trailing) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if ((amount != null) && (amount > 0)) {
    var formatted = removeTrailingZeros(formatter.format(amount).replace('$',''));
    return (formatted + (trailing || ''));
  } else {
    return null;
  }
};

/*
Format a number as dollars with $ and commas, "trailing" is used to include any decimals manually typed in by the user
*/
const formatDollars = (amount, trailing) => {
  if (!isFinite(amount)) {
    return null;
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  if ((amount != null) && ((amount > 0) || (trailing == '.'))) {
    var formatted = removeTrailingZeros(formatter.format(amount));
    if (formatted == 'NaN') { formatted = '' };
    return (formatted + (trailing || ''));
  } else {
    return null;
  }
};

/*
Format a number as dollars with commas and %, "trailing" is used to include any decimals manually typed in by the user
*/
const formatPercent = (amount, trailing) => {
  if ((amount != null) && ((amount > 0) || (trailing == '.'))) {
    var formatted = removeTrailingZeros(String(amount));
    if (formatted == 'NaN') { formatted = '' };
    return (formatted + (trailing || '') + '%');
  } else {
    return null;
  }
};

// Take a state value (typically includes formatting like dollars or percent) and convert to a number that we can do math with
const convertToNumber = (input) => {
  var value = String(input).replace(/[^0-9.]/g, '');
  if ((value != null) && (value != '')) {
    return parseFloat(value);
  } else {
    return 0;
  }
};


/*
Reducer is called whenever a value is changed in a field, it updates state and then the page renders again

action.name is the name of the field or action, action.value is the value being updated
*/
function reducer(state, action) {

  var calculatedValues;

  // If loading or initial load results, can return right away and don't need to do everything else below
  if (action.name == 'loading') {
    return { ...state, loading: action.value };
  } else if (action.name == 'showOptionalDetails') {
    if (action.value == 'true') {
      return { ...state, showOptionalDetails: true };
    } else {
      return { ...state, showOptionalDetails: false };
    }
  } else if (action.name == 'onInitialLoad') {
    calculatedValues = calculate({ ...state, ...action.value });
    return { ...state, ...action.value, ...calculatedValues };
  }


  var value = action.value;

  // Trailing is used to include any decimals manually typed in by the user
  var trailing = '';

  // If field is a percent, check if user deleted the % symbol at the end and assume they intended to delete the last digit instead
  if ((action.name.toLowerCase().includes('percent')) && (value != null) && (value.length > 0) && (state[action.name] != null)) {
    if ((String(state[action.name]).includes('%')) && (!value.includes('%'))) {
      value = value.substring(0,value.length-1);      
    }
  }

  // For non-toggle fields
  if (!action.name.toLowerCase().includes('type')) {

    // Remove any characters that are not a number or decimal
    value = value.replace(/[^0-9.]/g, '');

    if ((value != null) && (value != '')) {
      if (value.includes('.')) {

        // Capture decimals types by the user so we can re-add them later; this is important if user types a decimal but has not yet typed the next digit
        trailing = value.substring(value.indexOf('.'),value.length);

        // Drop any decimals the user has typed after two; for example, if they type 1.015, drops the 5
        if (value.indexOf('.') == value.length-4) {
          value = value.substring(0,value.length-1);
        }
      }    

      value = parseFloat(value);

      // If the converted number still has decimals, then clear trailing so we don't accidentally have decimals appear twice
      if (String(value).includes('.')) {
        trailing = '';
      }

    } else {
      value = 0;
    }

  }

  var average = convertToNumber(state.average);
  var customers = convertToNumber(state.customers);
  var totalSales = convertToNumber(state.totalSales);

  switch (action.name) {
    case 'businessSizeType':
      calculatedValues = calculate({ ...state, businessSizeType: action.value });
      return saveAndReturn({ ...state, businessSizeType: action.value, ...calculatedValues });
    case 'givingType':
      calculatedValues = calculate({ ...state, givingType: action.value });
      return saveAndReturn({ ...state, givingType: action.value, ...calculatedValues });

    case 'customers':
      calculatedValues = calculate({ ...state, customers: value, totalSalesAuto: (value * average) });
      return saveAndReturn({ ...state, customers: formatNumber(value, trailing), totalSalesAuto: formatDollars(value * average), averageAuto: formatDollars(totalSales / value), ...calculatedValues  });
    case 'average':
      calculatedValues = calculate({ ...state, totalSalesAuto: (customers * value) });
      return saveAndReturn({ ...state, average: formatDollars(value, trailing), totalSalesAuto: formatDollars(customers * value), ...calculatedValues });
    case 'totalSales':
      calculatedValues = calculate({ ...state, totalSales: (value) });    
      return saveAndReturn({ ...state, totalSales: formatDollars(value, trailing), averageAuto: formatDollars(value / customers), ...calculatedValues });

    case 'givingPercent':
      calculatedValues = calculate({ ...state, givingPercent: (value) });    
      return saveAndReturn({ ...state, givingPercent: formatPercent(value, trailing), ...calculatedValues });
    case 'givingAmount':
      calculatedValues = calculate({ ...state, givingAmount: (value) });    
      return saveAndReturn({ ...state, givingAmount: formatDollars(value, trailing), ...calculatedValues });
    case 'participationPercent':
      calculatedValues = calculate({ ...state, participationPercent: (value) });    
      return saveAndReturn({ ...state, participationPercent: formatPercent(value, trailing), ...calculatedValues });

    case 'customerIncreasePercent':
      calculatedValues = calculate({ ...state, customerIncreasePercent: (value) });
      return saveAndReturn({ ...state, customerIncreasePercent: formatPercent(value, trailing), ...calculatedValues });
    case 'marketingIncreasePercent':
      calculatedValues = calculate({ ...state, marketingIncreasePercent: (value) });
      return saveAndReturn({ ...state, marketingIncreasePercent: formatPercent(value, trailing), ...calculatedValues });

    case 'profitPercent':
      calculatedValues = calculate({ ...state, profitPercent: (value) });
      return saveAndReturn({ ...state, profitPercent: formatPercent(value, trailing), ...calculatedValues });

    default:
      throw new Error();
  }
}

/*
Save the updated field values to Firestore if we have a companyId
Then return them back to the reducer so it can update state and re-render
*/
function saveAndReturn(values) {
  if (values.companyId != null) {

    // Values are saved to the calculator map on the companies doc in Firestore
    const saveFirestoreData = async () => {
      const updatedDoc = doc(db, "companies", values.companyId);
      await updateDoc(updatedDoc, { calculator: values });
    };

    saveFirestoreData();
  }
  return values;
}

/*
Calculate remaining fields (donation, subscription fee, gross and net ROI) if we have all of the required data points
*/
function calculate(values) {

  var donation;
  var subscriptionFee;
  var grossROI;
  var netROI;

  // Pick the correct totalSales value to use depending on which businessSizeType toggle is selected
  var totalSales = values.totalSales;
  if (values.businessSizeType == 'customers') {
    totalSales = values.totalSalesAuto;
  }

  if (values.givingType == 'percent') {

    if (readyToCalculate([totalSales, values.givingPercent, values.participationPercent])) {
      donation = convertToNumber(totalSales) * (convertToNumber(values.givingPercent)/100) * (convertToNumber(values.participationPercent)/100);
      subscriptionFee = getFee(convertToNumber(values.customers), donation);
    }

  } else {

    if (readyToCalculate([values.customers, values.givingAmount, values.participationPercent])) {
      donation = convertToNumber(values.customers) * convertToNumber(values.givingAmount) * (convertToNumber(values.participationPercent)/100);
      subscriptionFee = getFee(convertToNumber(values.customers), donation);
    }

  }

  // If we were able to calculate donation amount and subscription fee, then see if we can also calculate gross ROI
  if ((donation != null) && (subscriptionFee != null)) {

    if (readyToCalculate([totalSales, values.customerIncreasePercent]) || readyToCalculate([totalSales, values.marketingIncreasePercent])) {

      // Gross ROI calculate, this returns a total number of increased customer spend/retention + new customers from marketing
      grossROI = (convertToNumber(totalSales) * (convertToNumber(values.participationPercent)/100) * (convertToNumber(values.customerIncreasePercent)/100)) + (convertToNumber(totalSales) * (convertToNumber(values.marketingIncreasePercent)/100));

      // If user entered a profit margin, then also calculate net ROI
      if (readyToCalculate([values.profitPercent])) {
        netROI = grossROI * (convertToNumber(values.profitPercent)/100);
      }
    }
  }

  return { donation: formatDollars(donation, null), subscriptionFee: formatDollars(subscriptionFee, null), grossROI: formatDollars(grossROI, null), netROI: formatDollars(netROI, null) };

}


function getFee(customers, donation) {
  if (donation <= 10000) {
    return 1500;
  } else if (donation <= 50000) {
    return 5000;
  } else if (donation <= 300000) {
    return 30000;
  } else {
    return 30000 + (donation * 0.1);
  }
}


/*
Check if all values needed for calculation are not null and not zero
Loops through a list of all values passed to it, if they are all ok then returns true but if any are not then returns false
*/
function readyToCalculate(valuesList) {
  var ready = true;
  for (let i = 0; i < valuesList.length; i++) {
    if ((valuesList[i] == null) || (convertToNumber(valuesList[i]) == 0)) {
      ready = false;
    }
  }
  return ready;
}



/*
Return the Grateful business signup form URL either with their company ID (if we have it) or without
*/
function signupFormUrl(companyId) {
  if ((companyId != null) && (companyId != '')) {
    return "https://signup.begrateful.org/business?id=" + companyId;
  } else {
    return "https://signup.begrateful.org";
  }
}



/*
This is the main function that renders the UI
*/
export default function Calculator() {

  // useReducer updates state when dispatch is called and re-renders UI
  const [state, dispatch] = React.useReducer(reducer, initialState);

  // Must declare this here to be able to check for URL parameters, cannot be declared inside a condition
  const search = useLocation().search;

  // On initial load, need to check for saved data first
  if (state.initialLoad) {
    checkForSavedValues(search, dispatch);

    // Update state so this is only called once (I believe it is ok to do this without calling dispatch/reducer because it does not require any re-rendering)
    state.initialLoad = false;
  }

  return (
    <>
    {/* Backdrop is the MUI loader overlay with a progress indicator added, only displays when state.loading == true */}
    {state.loading && (
      <>
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={true}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )}
    {/* Header with company name (or generic instructions if we don't know the company name) */}
    <div className="container narrow text-center">
      <div className="mb-3 header-description">
        <div className="p-big">
          <p>
            {state.companyName}
          </p>
        </div>
      </div>

      {/* Button with link to business signup form; only show if we have a companyId */}
      {state.companyId != null && (
        <>
          <MuiButton href={signupFormUrl(state.companyId)} style={{ marginTop: -10, marginBottom: 45 }}>
            Click Here to Start Using Grateful
          </MuiButton>
        </>
      )}

      {/* First table: business size */}
      <TableContainer component={Paper} sx={{ maxWidth: 850 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="center">
                <div style={{float: "left",}}>
                  <Typography pt={1} sx={{ fontSize: ".85rem" }} color="#909090" ><strong>BUSINESS SIZE:</strong></Typography>
                </div>
                <div style={{float: "right"}}>
                  <FormControl>
                    <RadioGroup
                      row
                      name="businessSizeType"
                      defaultValue="customers"
                      onChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                    >
                        <FormControlLabel value="customers" control={<Radio size="small" sx={{ color: "#909090" }} />} label={<Typography sx={{ fontSize: ".85rem" }} color="#909090">Begin with number of customer/clients</Typography>} />
                        <FormControlLabel value="sales" control={<Radio size="small" sx={{ color: "#909090" }} />} label={<Typography mr={-2} sx={{ fontSize: ".85rem" }} color="#909090">Begin with total sales</Typography>} />
                    </RadioGroup>
                  </FormControl>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {state.businessSizeType == "sales" && (
              <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Total annual sales
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="totalSales"
                      value={state.totalSales || ''}
                      handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}

            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                Number of customers or clients
              </TableCell>
              <TableCell align="right">
                <InputField
                  name="customers"
                  value={state.customers || ''}
                  handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                />
              </TableCell>
            </TableRow>

            {state.businessSizeType == "sales" && (
              <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Average annual spend per customer/client
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="averageAuto"
                      value={state.averageAuto || ''}
                      handleChange={(e) => null}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              </>
            )}

            {state.businessSizeType == "customers" && (
              <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Average annual spend per customer/client
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="average"
                      value={state.average || ''}
                      handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Total annual sales
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="totalSalesAuto"
                      value={state.totalSalesAuto || ''}
                      handleChange={(e) => null}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              </>
            )}

          </TableBody>
        </Table>
      </TableContainer>
      </div>

    {/* Second table: giving */}
      <div className="mt-2 container narrow text-center">
      <TableContainer component={Paper} sx={{ maxWidth: 850 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="center">
                <div style={{float: "left",}}>
                  <Typography pt={1} sx={{ fontSize: ".85rem" }} color="#909090" ><strong>GIVING:</strong></Typography>
                </div>
                <div style={{float: "right"}}>
                  <FormControl>
                    <RadioGroup
                      row
                      name="givingType"
                      defaultValue="percent"
                      onChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                    >
                      <FormControlLabel value="percent" control={<Radio size="small" sx={{ color: "#909090" }} />} label={<Typography sx={{ fontSize: ".85rem" }} color="#909090">Donate a percent</Typography>} />
                      <FormControlLabel value="fixed" control={<Radio size="small" sx={{ color: "#909090" }} />} label={<Typography mr={-2} sx={{ fontSize: ".85rem" }} color="#909090">Donate a fixed amount</Typography>} />
                    </RadioGroup>
                  </FormControl>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.givingType == "percent" && (
              <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Grateful Giving amount (percent)
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="givingPercent"
                      value={state.givingPercent || ''}
                      handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
            {state.givingType == "fixed" && (
              <>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Grateful Giving amount (fixed amount per customer)
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="givingAmount"
                      value={state.givingAmount || ''}
                      handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                    />
                  </TableCell>
                </TableRow>
              </>
            )}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                Percent of customers that will pick a charity
              </TableCell>
              <TableCell align="right">
                <InputField
                  name="participationPercent"
                  value={state.participationPercent || ''}
                  handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </div>

      {/* Third table: costs (donation amount and subscription fee) */}
      <div className="mt-2 container narrow text-center">
      <TableContainer component={Paper} sx={{ maxWidth: 850 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="left">
                <Typography mt={1} mb={1} sx={{ fontSize: ".85rem" }} color="#909090"><strong>COSTS:</strong></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                Charitable donations
              </TableCell>
              <TableCell align="right">
                <InputField
                  name="donation"
                  value={state.donation || ''}
                  handleChange={(e) => null}
                  disabled
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                Grateful subscription fee
              </TableCell>
              <TableCell align="right">
                <InputField
                  name="subscriptionFee"
                  value={state.subscriptionFee || ''}
                  handleChange={(e) => null}
                  disabled
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </div>

      {/* Fourth table: return on investment */}
      <div className="mt-2 container narrow text-center">
      <TableContainer component={Paper} sx={{ maxWidth: 850 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} align="left">
                <Typography mt={1} mb={1} sx={{ fontSize: ".85rem" }} color="#909090"><strong>RETURN ON INVESTMENT:</strong></Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                Increase in customer retention or spend
              </TableCell>
              <TableCell align="right">
                <InputField
                  name="customerIncreasePercent"
                  value={state.customerIncreasePercent || ''}
                  handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                Increase in customer attraction (marketing)
              </TableCell>
              <TableCell align="right">
                <InputField
                  name="marketingIncreasePercent"
                  value={state.marketingIncreasePercent || ''}
                  handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                />
              </TableCell>
            </TableRow>
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                <strong>Total Return on Investment</strong>
              </TableCell>
              <TableCell align="right">
                <BoldInputField
                  name="grossROI"
                  value={state.grossROI || ''}
                  handleChange={(e) => null}
                  disabled
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      </div>

    {/* Fifth table: optional, profit margin and net ROI */}
      <div className="mt-2 mb-3 container narrow text-center">
      {state.showOptionalDetails && (
        <>
          <TableContainer component={Paper} sx={{ maxWidth: 850 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <div style={{float: "left",}}>
                      <Typography mt={1} mb={1} sx={{ fontSize: ".85rem", fontStyle: "italic" }} color="#909090">Optional details</Typography>
                    </div>
                    <div style={{float: "right"}}>
                      <IconButton
                        onClick={() => dispatch({ name: 'showOptionalDetails', value: 'false' })}
                      >
                        <ExpandLessIcon/>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Profit margin
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="profitPercent"
                      value={state.profitPercent || ''}
                      handleChange={(e) => dispatch({ name: e.target.name, value: e.target.value })}
                    />
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row" style={{fontSize: "1rem"}}>
                    Net return on investment
                  </TableCell>
                  <TableCell align="right">
                    <InputField
                      name="netROI"
                      value={state.netROI || ''}
                      handleChange={(e) => null}
                      disabled
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {!state.showOptionalDetails && (
        <>
          <TableContainer component={Paper} sx={{ maxWidth: 850 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell colSpan={2} align="center">
                    <div style={{float: "left",}}>
                      <Typography mt={1} mb={1} sx={{ fontSize: ".85rem", fontStyle: "italic" }} color="#909090">Optional details</Typography>
                    </div>
                    <div style={{float: "right"}}>
                      <IconButton
                        onClick={() => dispatch({ name: 'showOptionalDetails', value: 'true' })}
                      >
                        <ExpandMoreIcon/>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </>
      )}

      {/* Button with link to business signup form; only show if we have a companyId */}
      {state.companyId != null && (
        <>
          <MuiButton href={signupFormUrl(state.companyId)} style={{ marginTop: 50, marginBottom: 45 }}>
            Click Here to Start Using Grateful
          </MuiButton>
        </>
      )}

    </div>

    </>

  );
}