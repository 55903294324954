import Button from "@mui/material/Button";

const MuiButton = (props) => (
  <Button
    sx={{ paddingTop: 1, paddingBottom: 1, paddingLeft: 4, paddingRight: 4 }}
    variant="contained"
    color="success"
    {...props}
  />
);

export default MuiButton;