import TextField from "@mui/material/TextField";

export default function TextInput({ name, value, handleChange, ...props }) {
  return (
    <TextField
      id={name}
      name={name}
      label=""
      variant="outlined"
      inputProps={{ style: { fontWeight: 'bold', padding: 10, textAlign: 'center' } }}
      sx={{
        "width": 150,
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "black"
        },
      }}
      value={value || ''}
      onChange={handleChange}
      {...props}
    />
  );
}
