import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import "firebase/auth";

import { firebaseConfig } from "./config";

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();
const storage = getStorage();

export { firebaseApp, db, storage };
